.BasicRuleType {
    width: 25%;
    font-weight: bold;
}

.ResponseRuleType {
    float: left;
    font-size: 12px;
    padding-top: 2px;
}

.ResponseRuleType label {
    margin-bottom: 1px;
} 

.ResponseRuleType select {
    font-size: 12px;
    padding: 0 !important;
    height: auto !important;
    width: auto !important;
}
.leftNav {
    position: fixed;
    width: 60px;
    top: 113px;
    left: 0px;
    background-color: #f6f6f6;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .15);
    bottom: 0px;
    z-index: 100;border-right:1px solid hsl(0, 0%, 80%);
    /* -webkit-transition-duration: .15s; */
}
.menu {
    display:flex;
    flex-direction: column; 
    margin: 8px;
    border-radius: 33px;
}
.turn {
	transform: rotate(180deg) !important;
	margin-top: 4px !important;
}
.menu div span {
    display:none
}
.menu div:hover{
    cursor:pointer
}
.slide .menu div span {
    display:block
}
.slide .leftNav {
	width: 200px;
}
.menu div .iconify{
    color: #000 !important;
}
.menu div.active{
    background-color: #EBECF0;
}
.menu div.active .iconify {
    color: #024890 !important;
    border: 0px !important;
    border-bottom: 0px solid #005A9E !important;
}
.active {   
    background: rgba(255, 255, 255, .25);
    color:blue;
}
.navToggler{
    position:absolute;
    top:30px;
    right:-15px;
    width:30px;
    height:30px;
    cursor:pointer
}
.navToggler:hover{
    background-color: #dae0e5;
}

@media only screen and (max-width: 767px){
    .leftNav{
        top:143px
    }
}
.customize-nav .nav-item a{ font-size: 16px !important; }
.leftside-menu .nav-pills .nav-link{ padding: 0 !important; margin: 10px auto !important; border: 0 !important; border-radius: 50% !important; width: 60px !important; display: block; height: 60px; line-height: 60px; background: #4e5a6c; }
.leftside-menu .nav-pills .nav-link.active { padding: 0 !important;  border-radius: 0 !important; width: 100% !important; border-left: 4px solid #fff !important;
}

.comment-tabing .nav-item a{ color: #949494 !important; font-weight: bold; }

.notify-user label { margin: 0; }

.custom-control-panel{ line-height: 16px !important; height: auto !important; }

.font-14 { font-size: 14px; }

/* Meghana start */
.public-DraftEditor-content {
    max-height: calc(99vh - 150px);
}

.gl-padding-0 {
    padding: 0px !important;
}
/* Meghana end */

ul , ol{
    /* padding: 0;
    list-style: none;
    list-style-type: none; */
}

.brand-guide{
    padding: 0 15px;
}

.brand-guide li{
    min-width: 150px;
}

.rule-list ul , .rule-instance{
    min-height: 99px;
    max-height: 132px;
}

.rule-instance{
    color: #757575;
}

.rule-content h4{
    font-size: 20px;
    font-weight: bolder;
    color: #20252a;
}

.rule-content b{
    color: #398244;
}

.bg-heading{
    min-height: 100px;
    background: #f6f6f6;
}

cite{
    font-style: normal;
}

.no-wrap{
    white-space: nowrap;
}

strong span{
    font-weight: normal;
}

.annotation-border-bottom{
    transition: 0.15s ease all;
}

.annotation-border-bottom:hover{
    background: #e6f5f0;
}

.annotation-border-bottom textarea{
    border-radius: 50px;
    resize: none;    
    line-height: 36px;
    font-size: 18px;
    color: #70787f;
}

.custome-dropdown {
    padding: 5px 0px;
    width: 200px;
    box-shadow: 0 0 6px #555;
    border-radius: 5px;
    right: 20px;
    top: 30px;
    z-index: 9;
    background: white;
}

.custome-dropdown li {
    font-size: 18px;
    transition: 0.15s ease all;
}

.custome-dropdown li:hover {
    color: white;
    background: #024890;
}

.custome-dropdown li:hover i{
    color: white !important;
}

.user-point{
    width: 30px;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    color: black;
    background: #ffce00;
}

.user-timing i{
    width: 20px;
    height: 20px;
    font-size: 10px;
    color: #fff;
    background: grey;
}

.user-timing cite{
    color: #919191;
}

.user-content p , strong{
    font-size: 18px;
}

.user-content button{
    background: #62b0b9;
}

.time-ago{
    margin-right: 100px;
}

.time-ago span{
    font-size: 18px;
    color: #3e9396;
}

.cursor-pointer{
    cursor: pointer;
}

.green{
    color: #029a8a;
}

.red{
    color: #a00001;
}

.yellow{
    color: #fed767;
}

.black-grey{
    color: #20252b;
}

.SimpleViewTable.handsontable thead th:last-child button.changeType{
    display: none!important;
}

.OpeningStatement.handsontable thead th:first-child button.changeType, .OpeningStatement.handsontable thead th:last-child button.changeType, .OpeningStatement.handsontable thead th:nth-last-child(2) button.changeType, .OpeningStatement.handsontable thead th:nth-last-child(3) button.changeType{
    display: none!important;
}

.SimpleViewTable span.fa{
    text-align: center;
    display: block;
    align-items: center;
}

.OpeningStatement span.fa{
    text-align: center;
    display: block;
    align-items: center;
}

.handsontable th {
    background-color: #024890 !important;
    color: white;
}

.card-header {
    padding: 0rem 0rem !important;
    /* color: black !important; */
    text-decoration: none !important;
}

.btn-link {
    color: black !important;
}

.tooltip_intent {
    padding: 0px !important;
    max-width: 260px !important;    
    opacity: 1 !important;
    background: #024890 !important;
    z-index: 8;
}

.tooltip_intent::before{
    border-left-color: #024890 !important;
    border-right-color: #024890 !important;
}

.tooltip_intent::after{
    border-top-color: #024890 !important;
}

.tooltip_intent span {
    word-break: break-all;
}

.tooltip_intent p {
    padding: 4px 10px !important;
    margin: 0 0 2px;
    border-bottom: 1px solid #fff;
}

.tooltip_intent p:last-child{
    border: 0;
}

.simple-rules-tab .nav-item {
    display: flex;
    height: 100%;
}

.simple-rules-tab .nav-link {
    display: flex;
    padding: .5rem 1rem;
    font-size: 14px;
    text-transform: uppercase;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #000;
}

/* rulesui */
.box-shadow{
    box-shadow: 0 0 2px #aaa;
}
 
.border-radius-2{
    border-radius: 2px;
}

.assign-button, .add-node-button, .modal-header {
    border-color: #024890 !important;
    background: #024890 !important;
}

.action-btn {
    color: white;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    font-size: 12px;
    background: #0c488f;
}
.action-btn.disabled {
    background: #9E9E9E;
    cursor: not-allowed;
}

.icon-btn {
    margin-left: 5px;
    margin-right: 5px;
}

.responsetxt {
    font-size: 12px;
    width: 250px;
}

.response-btn.btn-primary {
    border-color: #024890 !important;
    background-color: #024890 !important;
}

.jsoneditor-menu {
    border-color: #024890;
    background: #024890;
}

.jsoneditor {
    border-color: #024890;
}

.lheight-0{
    line-height: 0;
}

.table thead th:last-child {
    width: 40%;
}

.no-padding .table{
    margin: 0;
}

.genericrule-table{
    overflow: auto;
}

.genericrule-table select.form-control{
    padding: 0 3px;
}

.genericrule-table th{
    font-size: 14px;
    white-space: nowrap;
}

.genericrule-table th:first-child{
    width: 5%;
}

.genericrule-table th:nth-child(2){
    width: 5%;
}

.genericrule-table th:nth-child(3){
    width: 20%;
}

.genericrule-table th:nth-child(4){
    width: 20%;
}

.genericrule-table th:nth-child(5){
    width: 40%;
}

/* responsive */
@media only screen and (max-width: 767px){
    .m-pl-0{ padding-left: 0 !important; }
    .m-pr-0{ padding-right: 0 !important; }
    .m-pt-0{ padding-top: 0 !important; }
    .m-pb-0{ padding-bottom: 0 !important; }

    .top-bar-container{ padding: 10px; }
    .top-bar-container .col-8 span { font-size: 18px; }
    .top-bar-container .back-btn { font-size: 15px; }

    .leftside-menu{ width: 50px !important; }
    .leftside-menu > div{ min-width: 0 !important; }
    .leftside-menu div.row{ margin: 0; width: 100%; }
    .leftside-menu div.row > div{ padding: 0; }

    .navigation-item{ padding: 0 10px !important; }
    .navigation-item .nav{ align-items: center; }
    .navigation-item .nav-item a , .navigation-item .nav-item button{ padding: 5px!important; font-size: 13px !important; }

    .conversation-box{ padding: 15px 0 0; }

    .overflow-auto{ overflow: auto; }

    /* .m-left-menu{ position: fixed !important; z-index: 99999; left: 50px; width: calc(100% - 50px) !important;  } */
    .m-left-menu{ position: fixed !important; z-index: 99999; left: 50px; width: 250px !important;  }
    .m-left-menu .active.show div { margin: auto; }
    .m-left-menu > div > div { width: auto !important; }
    .m-left-menu > div > div > div { text-align: center; }    

}


.CoachingDropDown button {
    background-color: white !important;
    color: #000 !important;
}

/** Meghana Fade in style start */
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;} 
}
@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;} 
} 
.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
 }

.mt-10 {
    margin-top: 10px;
}
/** Meghana Fade in style end */

/** Modal large style start - meghana */
.modal-full {
}

.modal-full .modal-dialog {
    margin: 5vh !important;
}

.modal-full .modal-content {
    width: 90vw;
    height: 90vh;
}

.modal-backdrop.show {
    opacity: 0.08;
}
/** Modal large style end - meghana */

/** Call Flow custom css start - meghana */
.call-flow-settings .dropdown-toggle {
    padding: 0px;
}
.call-flow-settings .dropdown-toggle::before {
    content: none !important;
}
/** Call Flow custom css end - meghana */
/** Call Flow Global Classes start **/

.font-18 {
    font-size: 18px !important;line-height:24px;
  }
  .font-16 {
    font-size: 16px !important;line-height:20px;
  }
  .font-14 {
    font-size: 14px !important;line-height:20px;
  }
  .iconify{font-size:1.5rem ;color:#024890}
  .transition{-webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;}
  .nav-link .iconify{color:#000 !important;}
  .nav-link.active .iconify{color:#024890 !important;}
  #top-bar .btn:hover .iconify{font-size:1.5rem ;color:#fff !important;}
  #top-bar .btn{color:#024890;}
  #top-bar .btn:hover{color:#fff}
  .CallFlowStepicon2{float: left;}
  .icon-color{color:#024890 !important;;font-size: 20px;}
  .moreSubItem{position: absolute;right:5px;top:25px;width:220px; cursor: pointer;background-color:#fff;box-shadow:0px 2px 2px rgba(0,0,0,.25);display:none;z-index:9}
  .moreSubItem.active{display:block}
  .rightToggle{position: absolute;right:0px;top:70px;bottom: 0px;width:300px;background-color:#fff;box-shadow:0px 5px 6px rgba(0,0,0,.25);height:88vh;transition: all .25s ease;z-index: 9}
  .StepSettings{position: absolute;right:5px;top:25px;width:220px; cursor: pointer;background-color:#fff;box-shadow:0px 2px 2px rgba(0,0,0,.25);}
  .blue{color:#0053b3}
  

.TP-overlay{position: absolute;right:20px;top:auto;bottom:auto;left:20px; cursor:default;background: rgba(230,230,230,.85);opacity:.95;z-index: 9;}
.cardMenu button:after {
	display: none
}
.cardMenu .dropdown-menu.show {
	left: auto !important;
	right: 0px !important;
	transform: translate3d(-5px, 29px, 0px)!important;
}
.cardMenu .dropdown-toggle{padding:0px!important}
.p-30{padding:30px}
.bg-light-blue{background-color:#F3F6F9}
.testPlan-table td{border-bottom:1px solid #ccc;padding:12px!important;font-size:14px;vertical-align: middle;}
.testPlan-table th{background-color:#F3F6F9;color:#024890;padding:12px;font-weight:600}
.testPlan-table .table-striped tbody tr:nth-of-type(odd) {
    background-color: #e4e4e4;
}
.testPlan-table thead th:last-child{width:auto!important}
.btn-blue:hover{background-color: #024890!important;color:#fff!important}


.TestPlanMore{position: absolute;right:25px;top:auto; cursor: pointer;}
.TestPlanMoreReport{position: absolute;right:25px;top:0px; cursor: pointer;}
.TestPlanMoreSubItem{position: absolute;right:5px;top:25px;width:160px; cursor: pointer;background-color:#fff;box-shadow:0px 2px 2px rgba(0,0,0,.25);display:none;z-index:99;transition: all .3s ease;}
.TestPlanMoreSubItem.active{display:block}
.user-icon{position: absolute;
    left: 20px;width:40px;height:40px;
    top: 10px;    
    background: #F3F6F9;}
    .bot-icon{position: absolute;width:40px;height:40px;
        right: 20px;
        bottom: 10px;    
        background: #F3F6F9;}
    .ml-100{margin-left: 100px;}
    .mr-100{margin-right: 100px;}
    .ml-70{margin-left: 70px;}
    .mr-70{margin-right: 70px;}
    .ml-50{margin-left: 50px;}
    .mr-50{margin-right: 50px;}
    .TP-selected{border-color: #afc1d6!important;background-color: #F3F6F9;}
    .TP-selected::after{content:'';position: absolute;top:40%;right:-20px;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:15px solid #F3F6F9;border-right:8px solid transparent}
  #CallFlowInfoToggle {position: fixed;right:0px;top: 14.5vh;transition: all .5s ease; }
  .border.callFlowBox{padding:10px 50px 10px 20px !important;border-radius:4px;background-color:#F0F8FC!important;box-shadow:0px 2px 2px rgba(0,0,0,.25);border-left:2px solid #2384e5!important;display: flex;min-height: 5.3rem !important;margin-left: -12px;}
  .last-stmt.callFlowBox{background-color: #fbf5f5!important;border:1px solid #ebccd1!important;border-left:3px solid #a94442!important;}
  .testPlan-table thead th:last-child{width:auto!important}
  .failure{border: 1px solid #B51D2B !important;box-shadow: 1px 4px 10px 1px rgba(181, 29, 43, 0.25);}
  .btn-blue:hover{background-color: #024890!important;color:#fff!important}
  
      .bg-light.passable{background-color: #F0F8FC!important;
        /* border: 1px solid #ccc; */
        border-radius: 4px;}
        #callStep-Section {
            height: calc(100vh - 265px);
          }
  .colStep-1{width:16%}
  .colStep-2{width:68%}
  .colStep-3{width:16%}
  .utteranceToggle:hover div{color:#024890}
  

  /* start updated on 19/10/21 */
  .rightToggleInfo.Shrink{position: absolute;right:-880px;top:20px;bottom: 0px;width:420px;background-color:#fff;height:100% !important;transition: all .5s ease !important;z-index: 9 !important;box-shadow:0px 0px 0px rgba(0,0,0,.25);border-left:1px solid #ccc}
  .rightToggleTP.Shrink{position: fixed;right:-400px;top:180px;bottom: 0px;width:420px;background-color:#fff;height:100% !important;transition: all .5s ease !important;z-index: 9 !important;box-shadow:0px 0px 0px rgba(0,0,0,.25);border-left:1px solid #ccc}
  #callFlowScreen{margin-right:14px!important}
  #testReportScreen{margin-right:14px!important;height: calc(100vh - 200px);}
  #callFlowScreen.slideInfo{margin-right:420px!important}
  #callFlowScreen.slideTP{margin-right:420px!important};
  #callFlowScreen.slideInfo .rightToggleInfo.Shrink{right:-420px}
  #callFlowScreen.slideTP .rightToggleTP.Shrink{right: 0px}
  .text-truncate{text-overflow: ellipsis;white-space:nowrap;overflow:hidden;width:95%}
  .callFlowBox.green-highlight{        
    background: #FAFAFA;
    border-color:#3DCA4B!important;border-left: 2px solid #3DCA4B!important;
    box-shadow: 1px 4px 10px 1px rgba(61, 202, 75, 0.25)!important;}
    .shadow-new{box-shadow: 0 0 0 1px rgb(9 30 66 / 8%), 0 2px 4px 1px rgb(9 30 66 / 8%);}
/* end updated on 19/10/21 */

.sampleUtterance{background-color:#F6F6F6;border:1px solid #e4e4e4;border-radius:0px 12px 12px 12px;color:#000 !important;margin-left:-40px;margin-bottom:2px;}
.sampleUtterance div{display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;max-width:220px;max-height:74px;overflow: hidden;text-overflow: ellipsis !important;margin-top:0px!important;padding-bottom:0px!important;}
.intent{background-color:#fff;border:1px solid #e4e4e4;border-radius:6px;color:#000 !important;margin-bottom:7px;margin-left:-40px;text-overflow: ellipsis;
  overflow: hidden;
  width: 95%;}
.BotChat{display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;background-color:#0053b3;border:1px solid #e4e4e4;border-radius:12px 12px 0px 12px;color:#fff;margin-right:-40px;max-height:74px;overflow: hidden;text-overflow: ellipsis !important;}

  @media only screen and (max-width: 991px) {
  .colStep-1{width:200px!important}
  .colStep-2{width:600px!important}
  .colStep-3{width:200px!important}
  .callFLow{width:1000px!important;}
  }

/** Call Flow Global Classes start **/



/* Confirmation alert global style */
.react-confirm-alert-overlay{
    z-index: 999 !important;
}
.Card {
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.CardBody {
    height: 75vh;
    /* width: 97vw; */
    overflow: auto;
}

.actionButtons{
    border-color: #024890 !important;
    background: #024890 !important;
}

.zIndex{
    z-index: 999;
}

.selectFilter{
    width: 300px !important;
}

.MessageDiv {
    margin-top: 10px;
}

.SaveBtn {
    margin-right: 10px;
}

.SwitchLabel {
    font-weight: bold;
}

.SwitchTagOptionColor {
    margin-right: 5px;
    font-size: 16px;
}
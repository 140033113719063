.ModalHeader {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857px;
    background: #14508f;
    color: #fff;
}

.Title {
    font-size: 16px !important;
}
.ClipsDiv {
    padding: 5px 10px;
}

.ClipLabel {
    word-break: break-all;
}

.ClipsDiv .Clip {
    margin-top: 5px;
    background-color: #eee;
    border-radius: 10px;
    border: #ddd solid 1px;
    align-items: center;
}

.ClipsDiv .Clip .ClipDetails {
    padding: 5px 10px;
}

.ClipsDiv input {
    margin: 10px;
}
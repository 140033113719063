.TabRow {
    border-bottom: 1px solid #ddd;
    margin-top: 4rem
}

.Tab {
    padding: 0 !important;
    border: 1px solid #ddd;
    border-bottom: 0;
    background: #f3f3f3;
    /* border-top-left-radius: 5px; */
    /* border-top-right-radius: 5px; */
    /* margin-bottom: 20px; */
}

.Tab.Active {
    border-bottom: none;
    border-color: #024890;
    background: #024890;
}

.Tab.Active a {
    color: white;
}

.Tab.NotActive {
    background-color: #eee;
}

.ActionRow {
    background: #f3f3f3;
    border: 1px solid hsl(0, 0%, 80%);
}

.AutosaveLabel {
    margin-left: 5px;
    font-size: 10px;
    padding: 1px 4px;
    border-radius: 5px;
    word-break: initial;
    display: inline-block;
    background-color: #f3983e;
    color: #fff;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.AutosaveLabelFa {
    color: #1bb71b;
}

.Editoperation {
  font-size: 14px;
  text-transform: uppercase;
  color: #0c478f;
  font-weight: bold;
  padding-right: 0.5rem;
  vertical-align: sub;
}

.Editoperationdiv{
    float:right;
}

.NavDisable{
    background-color: #c7c7c7;
}


.luisDataDeleteButton{
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.luisWarningInfoContainer{
    margin-top: 1rem;
}

.luisWarningInfoLabel{
    font-size: 12px;
    color: #dc3545;
}

.luisWarningIcon{
    font-size: 12px;
    color: #dc3545;
}

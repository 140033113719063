.HeaderDiv {
    /* margin-top: 10px !important; */
}

.CornerDiv {
    text-align: right;
}

.Icon {
    margin-left: 15px;
}

.isDraftIcon {
    color: #FF9800;
}

.HeaderBtn {
    margin-left: 15px;
    font-size: .875rem !important;
}

.ControlGrp {
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-left: 6px;
    padding: 5px;
    display: inline-block;
}

.InJsonActionGrp {
    width: fit-content;
}

.JsonActionGrp {
    width: 180px;
}

.IconBtn {
    margin-left: -2px;
    margin-right: 4px;
}

.JsonSelect {
    width : 200px;
    border-radius: 5px;
    margin-left: 0px;
    padding: 0px;
    display: inline-block;
}

.JsonHistory {
    float: left;
    text-align: left;
}

.TestLink {
    margin-left: 5px;
}

.DiffCheckerDiv {
    width: 100%;
    height: 200px;
    overflow: auto;

}

.LargeModal {
    margin: 5vh !important;
}

.LargeModalContent {
    height: 90vh;
    width: 90vw;
    margin: 5vh !important;
}

.SyncIcon {
    background-color: #565454;
    padding: 5px;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #ddd;
}

.ResponseAlertListDiv {
    margin-bottom: 5px;
    /* padding: 5px; */
    max-height: 70px;
    overflow: auto;
}

.ResponseAlertList {
    margin: 0px;
}

.BtnStartOver {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.UnsavedChanges {
    color: #dc3545;
}
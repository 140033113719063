.underline{
    border-bottom:2px solid #024890
}

.entityContainer{
    position: relative
}
.entity{
    font-size: 10px;
    color: #024890;
    position: absolute;
    top: 7px;
    left: 0px;
    overflow:hidden;
    text-overflow:ellipsis
}
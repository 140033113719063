.utterancesContainer{
    max-width: 284px !important;
    /* width: 75% !important; */
    /* margin-left: 10px; */
    font-size: 1rem;
    font-weight: 320;
    overflow-y:auto;
    overflow-x: hidden;
    border:1px solid #D5D5D5;
    border-radius:5px;
    margin-top:20px;
    color: #463f3f;
    /* font-size: 0.8em; */
    float: right;
    margin-bottom: 15px;
    height: 100px !important;
    
}
.labelFont{
    font-size: 1rem;
    font-weight: 320;

}

.selectInput{
    width: 50%;
    margin-left: 10px;
    font-size: 1rem;
    font-weight: 120px;
    /* font-family: initial; */
    
}

.utteranceHeading{
    padding:5px;
    background-color: #f6f6f6;
    font-size: 1.2em;
}
.utterances{
    padding:5px 5px 5px 10px;
    border-top:1px solid #D5D5D5;
    border-radius:5px;
    line-height: 2rem;
    
}
.utterances:nth-child(2n+1 ){
    background-color: #f6f6f6;
}
.entityInJson{
    color: #ccc!important;
}
.noUtteranceMessage{
    margin-top: 10px;
    margin-bottom: 40px
}
.Option {
    text-align: left;
    display: flex;
}

.TypeMessageDiv {
    color: #6c757d;
    font-size: small;
    width: 100%;
    text-align: left;
   
}


.EntityModalContent{
    /* min-width:820px; */
    /* max-width:1820px; */
    max-width: 600px !important;
    margin-left: 25vw !important;
    margin-right:auto !important;
    overflow-y: hidden !important;
    
}
.EntityModalLabel{
    width: 75% !important;
}



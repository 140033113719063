.modal-header .close {
    color: #fff;
}

.modal-header .close:hover {
    color: #fff;
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 20vh auto !important;
    }
}
.OverlayContainer {
    position: fixed;
    height:100vh;
    width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1500;
    /* padding: 2%; */
    color: #fff;
    cursor: not-allowed;
}

.OverlayHeader{
    padding-top: 3%;
}

.OverlayContent{
   padding-top: 20%;
   text-align: center;
}

.LoaderText{
    margin-bottom: 8px;
}

.CustomMessageText{
    margin-top: 16px;
}
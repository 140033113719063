.TRow:last-child .TData {
    border-bottom: 0;
}

.THead {
    background-color: #0c478f;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    margin: 0;
    padding: 0.5rem;
    max-width: 500px;
}

.THead:last-child {
    border-right: 0;
}

.FilterHead {
    color: #000;
    font-weight: normal;
}

.ActionBtn {
    padding: 0px 5px;
    font-size: 20px;
}

.ClipDescIcon {
    color: #0c478f;
}

.NodeCell, .ResponseTextCell, .ResponseClipCell, .ResponseTagCell, .AltResponseCell, .ResponseActionsCell {
    word-break: break-all;
}

.NodeCell {
    width: 15%;
}

.ResponseTextCell {
    word-break: break-word;
    width: 25%;
}

.ResponseActionsCell {
    width: 10%;
}

.ResponseClipCell, .ResponseTagCell, .AltResponseCell {
    width: 40%;
}
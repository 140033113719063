

.progressBarButton{
    position: relative;
    
}

.closeButton{
    position: absolute;
    right: 2px;
    top: -3px;

    
}
.progressBar{

    min-width: 6rem;
    background-color: #D3D3D3;
    margin-Right:8px;
 }
 .progressBarContainer{
     height: 2rem !important;
 }
 .syncButton{
    height: 2rem !important; 
 }
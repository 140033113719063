.LoadingIcon {
    font-size: 100px;
}

.Overlay {
    position: fixed;
    height:100%;
    width: 100vw;
    background-color: #0000005c;
    z-index: 9;
    text-align: center;
    padding-top: 25%;
}
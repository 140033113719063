.Center {
    text-align: center;
}

.Option {
    text-align: left;
    display: flex;
}

.IntentSelect {
    margin-bottom: 20px;
    text-align: left;
}

.intentInJson {
    color: #ccc!important;
}

.TypeMessageDiv {
    color: #6c757d;
    font-size: small;
    width: 100%;
    text-align: left;
}
.ControlGrp {
    margin-left: 6px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.SyncIcon {
    background-color: #565454;
    padding: 5px;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #ddd;
}

.JsonSelect {
    width : 200px;
    border-radius: 5px;
    margin-left: 0px;
    padding: 0px;
    display: inline-block;
}

.BtnStartOver {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}
.SettingsBox {
    right: 0px;
    position: absolute !important;
    top: 0px;
    padding: 5px;
    /* height: 20px; */
    /* background-color: #fff; */
    cursor: pointer;
    z-index: 2;
}
.multiPathSettingsBox {
    position: fixed;
    right: 15px;
    background-color:#0053b3;
    border-radius: 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.BackTrans {
    background: transparent;
}

.SettingsDropDownToggle {
    position: absolute !important;
    top: 0;
    right: 0px;
}

.SettingsDropDownToggle .dropdown-toggle::before {
    content: none !important;
}

.InterruptionCard {
    right: 30px;
    min-width: auto !important;
    max-width: 250px;
    font-size: 12px;
    padding: 10px;
    background-color: #f7f7f7 !important;
}

.InterruptionCardHeader {
    background-color: transparent !important;
    border: none !important;
}

.DefaultUtteranceCard {
    right: 30px;
    min-width: auto !important;
    max-width: 250px;
    font-size: 12px;
    padding: 10px;
    background-color: #aad8ff !important;
}

.DefaultUtteranceCardHeader {
    background-color: transparent !important;
    border: none !important;
}
.DropDownSettingsSize{
    font-size: 12px;
    margin-top: -8px !important;
    margin-bottom: -8px;
    will-change: transform;
    transition: translated3d(-258px -35px 0px) !important;
    /* top:-7px !important; */
    /* height: 162px; */
    overflow: scroll;

}


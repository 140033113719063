.SettingsBox {
    right: 0px;
    position: absolute !important;
    top: 0px;
    padding: 5px;
    cursor: pointer;
    z-index: 2; 
}

.BackTrans {
    background: transparent;
    color: #f7f7f7;
    font-size: 25px;
}

.SettingsDropDownToggle {
    position: absolute !important;
    top: 0;
    right: 0;
}

.SettingsDropDownToggle .dropdown-toggle::before {
    content: none !important;
}

.InterruptionCard {
    min-width: auto !important;
    max-width: 250px;
    font-size: 12px;
    padding: 10px;
    background-color: #f7f7f7 !important;
}

.InterruptionCardHeader {
    background-color: transparent !important;
    border: none !important;
}
.DropMenuStyle{
    
    font-size: 11px !important;
    padding-top: 0px !important;
    margin-top: -38px !important;
    will-change: transform;
    transition: translated3d(-258px -35px 0px) !important;
    top:18px !important;

}


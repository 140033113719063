.CallFlowInfoToggle {position: fixed;right:0px;top: 0px; width:82% !important;  box-shadow: 0 0 0 50vmax rgba(0,0,0,.08) !important;}
.SideBar1{
    word-break: break-all;
}
.sidebarBody{
    height: 93%;
    overflow: scroll;
    position: relative !important;
}
.rulesContainer{
    border-bottom: 1px solid lightgray;
    padding: 10px 0px
}

.addUtteranceTextInput{
  padding: 0.5rem 0.5rem;
  width: 100%;
  border-color: #cccccc
}

.cursorDisabled{
 cursor: not-allowed;
}

.tableContainer{
    margin-top: 0.5rem;
    height: 250px;
    overflow-y: scroll;
}

table.customTable {
    width: 100%;
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #F8F8F8;
    border-style: solid;
    color: #000000;
  }
  
  table.customTable td, table.customTable th {
    border-width: 2px;
    border-color: #F8F8F8;
    border-style: solid;
    padding: 5px;
  }
  
  table.customTable thead {
    background-color: #F8F8F8;
    position: sticky;
    width: 100%;
    
  }

  table.customTable .utteranceContent {
   padding-bottom: 1rem;
   width: 90%;
   cursor: pointer;
  }

  .utteranceText{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 14px;
}


.utterances{
  border-radius:5px;
  line-height: 2rem;
  
}

/* .utteranceHeading{
  padding:5px;
  background-color: #f6f6f6;
  font-size: 1.2em;
}

.utterances:nth-child(2n+1 ){
  background-color: #f6f6f6;
}
.entityInJson{
  color: #ccc!important;
}
.noUtteranceMessage{
  margin-top: 10px;
  margin-bottom: 40px
}
.Option {
  text-align: left;
  display: flex;
}

.TypeMessageDiv {
  color: #6c757d;
  font-size: small;
  width: 100%;
  text-align: left;
 
} */









.assign-button , .add-node-button , .modal-header {
    border-color: #024890 !important;
    background: #024890 !important;
  }
  
  .add-node-button.btn-secondary{
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }

  .icon-btn {
      margin-left: 5px;
      margin-right: 5px;
  }

  .no-padding {
      padding: 0px !important;
  }

  .play-icon {
    margin-left: 10px;
    margin-right: 10px;
    background: #0c488f;
    color: #fff;
    padding: 7px;
    border-radius: 50%;
  }

  .fixed-header-top{
    z-index: 1040;
    position: fixed!important;
    left: 0px;
    right: 0px;
    top: 0px;
    background-color: #fff;
  }
  .fixed-header{
    z-index: 1040;
    position: fixed;
    left: 10px;
    right: 10px;
    top: 55px;
    background-color: #fff;
    /* height:120px; */
  }

.StoryFlowCon{
    padding-top:10px !important;
}

.ListStyle{
    display: flex;
    padding: grid;
    overflow-x: auto;
    overflow-y: hidden;
    /* margin: 10px 0px; */
    background: #eeeeee !important;
    width: 100% !important;
    max-width: 600px ​!important;
    max-height: 100px ​!important;
    position: relative;
}
.ListStyle p {
    display: -webkit-box;
    max-width: 600px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ItemStyle {
    min-width:305px !important;
    max-width: 305px;
}

.SampleUtterance{background-color:#F6F6F6;border:1px solid #e4e4e4;border-radius:0px 12px 12px 12px;color:#000 !important;margin-left:-40px;margin-bottom:2px;}
.SampleUtterance div{display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;max-width:220px;max-height:74px;overflow: hidden;text-overflow: ellipsis !important;margin-top:0px!important;padding-bottom:0px!important;}
.SampleUtteranceText{
    margin-top: 20px !important;
    padding-bottom: 20px !important;
}

.SampleUtteranceTextNotFound{
    margin-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 15px !important;
}

.ResponseBlock {
    color: rgb(255, 255, 255);
    position: relative;
    left: 5px;
    width: 400px;
    height: auto;
}
.IntentInline{
    background-color: #f1f1f1 !important;
    border-color: rgb(219, 219, 219) !important;	
    color: rgb(0, 0, 0);
    margin-left: 5px;
    margin-top: 5px;
    padding: 8px;
    width: 120px;
    border-radius: 3px;
    align-items: center;
    min-height: 10px;
}
.IntentInline2{
    background-color: #f1f1f1 !important;
	border-color: rgb(219, 219, 219) !important;
	color: rgb(0, 0, 0);
	margin-left: 5px;
	margin-top: 20px;
	padding: 8px;
	width: 120px;
	 /* height: 60px; */
	border-radius: 3px;
	align-items: center;
	min-height: 10px;
}

.SampleUtteranceIntent{
    border-color: #fff !important;
    color: #000;
    /* margin-left: 5px; */
    float: left;
    width: 450px;
    border-radius: 2px;
}

.Response {
    padding: 1px;
}

.NormalItemStyle {
    background-color: #f7f7f7 !important;
}

.NormalItemStylePassable {
    background-color: #ebe8e8 !important;
}

.CorruptedItemStyle {
    background-color: #f8d7da !important;
    color: #d9534f !important;
}

.LastStatementItemStyle {
    background-color: #f0ad4e !important;
    color: white !important;
}

.cardHeader{
    /* color:white !important; */
}

.ItemBody{
    padding: 5px 5px 0px 0px !important;
}

.NonStrictResponse {
    background-color: #f4f4f4 !important;
}

.TempListStyle{
    background: #ffffff  !important;
    min-height: 10px;
}

.addStepView{
    /* z-index: inherit 999;
    position:relative;
    cursor: pointer;
    text-align: center;
    padding: 2px;
    opacity: 1;
    transition: opacity 1s linear; */

    position: absolute;
    cursor: pointer;
    text-align: center; 
    padding: 6px;
    opacity: 1;
    transition: opacity 1s linear;
    z-index: 2;
    right: 30px;
    background: transparent;
    border-radius: 5px;
}

.StepDiv {
    position: relative;
}

.ResponseBlockDelete {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    font-size: 12px;
    color: #fff;
    /* background-color: #dc3545; */
    /* border-color: #dc3545; */
}

.ResponseBlockDelete:hover {
    /* background-color: #c82333; */
    /* border-color: #bd2130; */
}


.BtnStartOver {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}

.RowStyle{
    /* margin: 10px 0px; */
    min-height: 150px !important;
    max-width: 600px ​!important;
    max-height: 100px ​!important;
}

.BorderRight {
    border-right: 2px inset white !important;
}
  
  .Inline{
    position:relative;
    display: inline-block;
    right:5px;
    font-size: 20px;
    color: #024890 !important;
  }

  .RightBlock{
      padding-bottom: 50px;
  }

  .PosRel{position: relative !important;}

  .CallFlowBox{padding:35px 50px 10px;border-radius:4px;background-color:#F0F8FC!important;box-shadow:0px 2px 2px rgba(0,0,0,.25);border-left:2px solid #2384e5!important}

  .Intent{background-color:#fff;border:1px solid #e4e4e4;border-radius:6px;color:#000 !important;margin-bottom:7px;margin-left:-40px;text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;}

  .CallFlowStepIcon{position: absolute;right:0px;top:10px; cursor: pointer;}

  .NoUtterances{border:1px solid #ebccd1;color:#a94442 !important;background-color: #fbf5f5;}

  .LastStmt.CallFlowBox{background-color: #fbf5f5!important;border:1px solid #ebccd1!important;border-left:3px solid #a94442!important;}
.Tag {
    margin-left: 5px;
    font-size: 10px;
    padding: 1px 4px;
    border-radius: 5px;
    word-break: initial;
    display: inline-block;
    background-color: #f3983e;
    color: #fff;
    /* overflow-wrap: break-word; */
}

.StoryFlowCon{
    padding-top:10px !important;
}

.ListStyle{
    display: flex;
    padding: grid;
    overflow-x: auto;
    overflow-y: hidden;
    /* margin: 10px 0px; */
    background: #eeeeee !important;
    width: 100% !important;
    max-width: 600px ​!important;
    max-height: 100px ​!important;
    position: relative;
}
.ListStyle p {
    display: -webkit-box;
    max-width: 600px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ItemStyle {
    margin-right:10px;
    width: 50%;
}

.sampleAgentUtteranceText{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 12px;
}

.SampleUtterance{
    /* background-color:#e7e3e3;
    border:1px solid #e4e4e4;
    border-radius:0px 12px 12px 12px;
    color:#000 !important;
    margin-bottom:2px;
    margin-right: 17px !important;
    height: 3.5rem;
    -webkit-line-clamp: 2;
    width: 6rem ;
    font-weight: 4; */
    display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;background-color:#e7e3e3;color:#000 !important;border:1px solid #e4e4e4;border-radius:12px 12px 0px 12px;margin-right:17px; overflow: hidden;text-overflow: ellipsis !important;cursor: pointer;width: 8rem;height: 3rem;font-weight: 300;
}
.CursorNotAllowed{
    cursor: not-allowed;

}
.CursorPointer{
    cursor: pointer;
    
}
.iconStyle{
    height:2rem;
}
.interruption{
    margin-top: -20px;
    vertical-align: center;
    height: 90%;
}
.getListStyle{
    min-height: 30px;
}
.setViewTypeStyle{
    position:absolute;
    top:8%;
    left:-45px;
    width:30px;
    height:30px;
    cursor:pointer;
    margin-left:30px;
}
.SampleUtterance div{
    display:-webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width:220px;
    max-height:74px;
    overflow: hidden;
    text-overflow: ellipsis !important;
    margin-top:0px!important;
    padding-bottom:0px!important;}
.SampleUtteranceText{
    margin-top: 20px !important;
    padding-bottom: 20px !important;
}
.ToggleButton{
    display:inline-flex;
    align-items:center;
}

.SampleUtteranceTextNotFound{
    margin-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 15px !important;
}

.ResponseBlock {
    color: rgb(255, 255, 255);
    position: relative;
    left: 5px;
    width: 400px;
    height: auto;
}
.IntentInline{
    background-color: #f1f1f1 !important;
    border-color: rgb(219, 219, 219) !important;	
    color: rgb(0, 0, 0);
    margin-left: 5px;
    margin-top: 5px;
    padding: 8px;
    width: 120px;
    border-radius: 3px;
    align-items: center;
    min-height: 10px;
}
.IntentInline2{
    background-color: #f1f1f1 !important;
	border-color: rgb(219, 219, 219) !important;
	color: rgb(0, 0, 0);
	margin-left: 5px;
	margin-top: 20px;
	padding: 8px;
	width: 120px;
	 /* height: 60px; */
	border-radius: 3px;
	align-items: center;
	min-height: 10px;
}

.SampleUtteranceIntent{
    border-color: #fff !important;
    color: #000;
    /* margin-left: 5px; */
    float: left;
    width: 450px;
    border-radius: 2px;
}

.Response {
    padding: 1px;
}

.NormalItemStyle {
    background-color: #f7f7f7 !important;
}

.NormalItemStylePassable {
    background-color: #ebe8e8 !important;
}

.CorruptedItemStyle {
    background-color: #f8d7da !important;
    color: #d9534f !important;
}

.LastStatementItemStyle {
    background-color: #f0ad4e !important;
    color: white !important;
}

.cardHeader{
    /* color:white !important; */
}

.ItemBody{
    padding: 5px 5px 0px 0px !important;
}

.NonStrictResponse {
    background-color: #f4f4f4 !important;
}

.TempListStyle{
    background: #ffffff  !important;
    min-height: 10px;
}

.addStepView{
    /* z-index: inherit 999;
    position:relative;
    cursor: pointer;
    text-align: center;
    padding: 2px;
    opacity: 1;
    transition: opacity 1s linear; */

    position: absolute;
    cursor: pointer;
    text-align: center; 
    padding: 6px;
    opacity: 1;
    transition: opacity 1s linear;
    z-index: 2;
    right: 30px;
    background: transparent;
    border-radius: 5px;
}

.StepDiv {
    position: relative;
}

.ResponseBlockDelete {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    font-size: 12px;
    color: #fff;
    /* background-color: #dc3545; */
    /* border-color: #dc3545; */
}

.ResponseBlockDelete:hover {
    /* background-color: #c82333; */
    /* border-color: #bd2130; */
}


.BtnStartOver {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}

.RowStyle{
    /* margin: 10px 0px; */
    min-height: 150px !important;
    max-width: 600px ​!important;
    max-height: 100px ​!important;
}

.BorderRight {
    border-right: 2px inset white !important;
}
  
  .Inline{
    position:relative;
    display: inline-block;
    right:5px;
    font-size: 20px;
    color: #024890 !important;
  }

  .RightBlock{
      padding-bottom: 50px;
  }

  .PosRel{
      position: relative !important;
    /* height:86px !important; */
    
} 

  .CallFlowBox{padding: 35px 50px 10px!important;border-radius:4px;background-color:#F0F8FC!important;box-shadow:0px 2px 2px rgba(0,0,0,.25);border-left:2px solid #2384e5!important;height: 102px;}

  .Intent{
    background-color:#fff;border:1px solid #e4e4e4;border-radius:6px;color:#000 !important;margin-bottom:7px;margin-left:-5px;margin-right: 50px; text-overflow: ellipsis;
    overflow: hidden;cursor: pointer;
    width: 100%;padding: 0.2rem;font-weight: 300 !important;height: 2.5rem;
}

.EntityStyle{
  color:#000 !important;margin-bottom:7px;text-overflow: ellipsis;
  overflow: hidden;font-weight: 400 !important;font-size: 9px !important;display: flex;
  flex-wrap: wrap;width: 7rem;width: 100%;
  
}
.EntityStyleStepLevel{
    color:#000 !important;margin-bottom:7px;text-overflow: ellipsis;
  overflow: hidden;font-size: 9px !important;display: flex;
  flex-direction: column;
  
}

.BotChat{
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
    background-color:#084489;
    border:1px solid #e4e4e4;
    border-radius:12px 12px 0px 12px;
    color:#fff;
    margin-right:-10px;
    margin-left: 10px; 
    /* width: 174.71px;  */
    overflow: hidden;
    text-overflow: ellipsis !important;
    cursor: pointer;
    width: 85%;
    height: 3.1rem;
    font-weight: 300 !important;
}
  
  
.CallFlowStepIcon{right:0px;top:10px; cursor: pointer;padding-left: 24px;margin-right: -31px;}


.NoUtterances{border:1px solid #efeaeb;background-color: #fbf5f5;width:5rem}

.LastStmt.CallFlowBox{background-color: #fbf5f5!important;border:1px solid #ebccd1!important;border-left:3px solid #a94442!important;}

.noInterruption{
    color: #721c24;
    font-weight: 400;
}

.justifyContentCenter {
    font-weight: 500;
}

.StepIndexClass{
    position: absolute;
    width: 26px;
    height: 25.61px;
    background: rgba(35, 132, 229, 0.14);
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 10px;
    color: #000000;

}

.AgentBlock{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    position: relative;
}

.stepNumbers{
    position: absolute;
    top: 7px;
    left: 10px;
    padding: 0px 2px;
    background-color: rgb(220,233,247);
}

.TextCenter{
    height: 2rem !important;
}

.selectedSteps{
    background-color: #FCEEED;
}

.multiPathSettings{
    position: absolute;
    right:-10px;
    top:5px;
    cursor: pointer;
}

.accordionDimensions{
    min-width: 40px;
    margin-left: 10px;
}

.accordianSection{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.orAccordian{
    background: #5C5C5C;
    color: #FFFFFF
}

.andAccordian{
    background: #F1C04E;
    color: #000000
}
.accordianLastStep{
    margin-bottom: 10px;
}

.verticalText{
    writing-mode: vertical-rl;
    text-orientation: mixed
}

.addResponseBtn{
    margin: auto;
    width: 50%;
    height: 5.3rem;
    border: 1px dashed black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.DefaultUtteranceBlock{
    height:50px !important;
}
.ColStepStyle{
    overflow-x: auto;
}
.container{
    width:102%;
    min-height: 6rem;
}

.buttonDisabled{
    background-color: #ccc;
    cursor: not-allowed;
}
.startTrainingButton{
    height: 2rem;
}

.luisWarningIcon{
    font-size: 12px;
    color: #dc3545;
}
.luisWarningInfoLabel{
    font-size: 12px;
    color: #dc3545;
}

.luisDataDeleteButton{
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

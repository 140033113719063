.NewTopbar {
    color: #024890;
    background-color: #ffff;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);

}

.storyTitle{
    font-size: 25px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    min-height: 41px;
    line-height: 34px;
    font-family: 'latoregular', sans-serif;
    font-style: normal !important;
}

.BtnBack {
    font-weight: bold;
    color: #024890;
}

.BtnBack:hover {
    color: #024890;
    text-decoration: none;
}
@value bluebase: #024890;

:global(.gl-h100) {
    height: 100%;
}

:global(.gl-w100) {
    width: 100% !important;
}

:global(.gl-pageHeight) {
    height: 94vh;
}

:global(.gl-textCenter) {
    text-align: center;
}

/** Padding related */
:global(.gl-nopadding) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/** margin related */
:global(.gl-nomargin) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

/** Colors related */
/** Background Colors */
:global(.gl-bgBlack) {
    background-color: #000;
    color: #fff;
}

:global(.gl-bgRed) {
    background-color: red;
}

/** Text Colors */
:global(.gl-blue) {
    color: #024098;
}

/** Buttons */
:global(.gl-btnPrimaryCust) {
    color: #fff !important;
    background-color: #337ab7 !important;
    border-color: #2e6da4 !important;
}
:global(.gl-btnPrimaryCust:hover) {
    color: #fff !important;
    background-color: #286090 !important;
    border-color: #204d74 !important;
}

/** styles for tooltip used in whole app */
:global(.tooltip_cust) {
    background-color: #024890 !important;
    opacity: 1 !important;
    max-width: 250px;
}
:global(.tooltip_cust.place-bottom:after) {
    border-bottom-color: #024890 !important;
}
:global(.tooltip_cust.place-top:after) {
    border-top-color: #024890 !important;
}
:global(.tooltip_cust.place-left:after) {
    border-left-color: #024890 !important;
}
:global(.tooltip_cust.place-right:after) {
    border-right-color: #024890 !important;
}
:global(.tooltip_cust.show) {
    z-index: 999999;
}

:global(.gl-inlineBlock) {
    display: inline-block;
}

:global(.gl-displayNone) {
    display: none;
}
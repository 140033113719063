.TodoList {
    font-family: 'CenturyGothic';
}

.TodoNumber {
    background-color: #eec672;
    color: #ffffff;
    cursor: pointer;
    padding: 2px 12px;
    text-align: center;
    border-radius: 50%;
}

.Popover {
    max-width: 450px !important;
}

.List {
    font-family: 'CenturyGothic';
    max-height: 400px;
    overflow-y: auto;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.SwitchViewHeading {
    cursor: pointer;
}
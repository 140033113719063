.Table {
    border-spacing: 0;
    border: 1px solid black;
    table-layout: fixed;
}

.TRow:last-child .TData {
    border-bottom: 0;
}

.THead {
    background-color: #0c478f;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}

.TData {
    border-top: 1px solid black;
    border-right: 1px solid black;
    word-break: break-all;
}

.TdHide div{
    display: none;
}
.TdHide {
    border-top: none;
}

.THead, .TData {
    margin: 0;
    padding: 0.5rem;
}

.THead:last-child, .TData:last-child {
    border-right: 0;
}

.ClipDescIcon {
    color: #0c478f;
}

.tooltip {
    
}

.TRowHighlighted {
    background-color: #f8d7da;
}

.TRowHide {
    display: none;
}

.ClickableCell:hover {
    cursor: pointer;
    /* background-color: #ebebeb; */
}

.Intent {
    /* padding: 0px 5px; */
    /* border-radius: 5px; */
}

.ResponseTextCell {
    word-break: break-word;
    position: relative;
    cursor: pointer;
}

.ResponseTextCell:hover {
    background-color: #ddd;
}

.ActionItems {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.ActionBtn {
    padding: 0px 5px;
    font-size: 20px;
}

.InvalidNode {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
}
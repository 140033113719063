.TitleContainer {
   color: #fff;
}

.CloseIcon{
    cursor: pointer;
}

.ButtonContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem;
}

.annotatorCardContainer{
    margin-top: 1rem;
    padding: 0.5rem;
    line-height: 2;
}

.selectedUtteranceText{
    padding: 1rem 0;
}


.entityContainer{
    border-radius: 0.5rem;
    
}

.entityDelete {
    cursor: pointer;
    margin-left:3px;
    margin-right :3px
}

.trainedEntity {
    border-radius: 0.5rem;
    background-color: #6495ed;
    margin-left: 3px;
    color: #fff
}



.untrainedEntity {
    border-radius: 0.5rem;
    background-color: rgb(244, 116, 65);
    margin-left: 3px;
    color: #fff
}

.actionsContainer{
    padding: 1rem;
    gap: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.infoIcon{
    font-size: 1rem;
    cursor: pointer;
}

.actionsText{
    font-size: smaller
}

.buttonDisabled{
    background-color: #ccc;
    cursor: not-allowed;
}

.entityStyles {
    box-sizing: border-box;
    content : attr(data-entity);
    font-size:  .55em;
    line-height : 1;
    padding: .35em .35em;
    border-radius: .35em;
    text-transform : uppercase;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 .15rem .5rem;
    font-weight: 700;
}

.utteranceStyles{
    padding: .2em .3em;
    margin: 0 .25em;
    line-height: 1.5;
    display: inline-block;
    border-radius: .25em;
    background: none;
    text-decoration: underline;
}

.RuleResponseTable {
    padding: 15px;
}

.ActionDiv {
    padding: 0px;
}

.ActionDiv .NodeName {
    font-size: 14px;
}

.ActionButton {
    margin: 0px 15px;
}

.RuleResponseTable td, .RuleResponseTable th {
    padding: 5px;
}

.ResponseBox {
    border: 1px solid #dddddd;
    background-color: white;
    border-radius: 2px;
    padding: 5px;
}

.FromNChangeTo {
    max-height: 80px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 2px;
    padding: 5px;
}

.SelectableHeader {
    width: 40%;
    display: inline-block;
}

.ListBulkHeader {
    /* margin-left: 50%; */
    font-weight: bold;
}

.DoneBtn {
    margin-bottom: 10px;
    float: right;
}

.BulkActions {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 2px;
    padding: 5px;
}
.entitiesSection {
    display: flex;
    justify-content: space-between
}
.entitiesTruncate{
    height: 2.5rem;
    overflow: hidden;
}
.entitiesChipContainer {
    display: flex ;
    flex-wrap: wrap ;
    /* font-size: 0.6em */
}
.entityChip {
    color: white;
    border-radius: 5px;
    padding: 4px;
    margin-right: 4px;
    margin-bottom: 8px;
    position: relative;
    text-align: initial;
    cursor: pointer;
}
.chipIcons {
    color: white;
    font-size: 1.2rem
}


.entityCross > .iconify{
    color: white !important;
}
.entityIcon{
    display: flex;
    flex-direction: row-reverse;
}

.Overdue {
    margin-bottom: 5px;
}

.Inner {
    padding: 5px;
    width: 100%;
}

.Overdue .Inner {
    background: #F2DEDF;
}

.NonOverdue .Inner {
    background: #F2DEDF;
}


.Blue {
    background: #096bcd;
}

.Green {
    background: #78a941;
}

.Red {
    background: #f15d58;
}

.Yellow {
    background: #fec542;
}

.Collect {
    background: #ed4cef;
}

.InnerSpan {
    display: inline-block;
    text-align: center;
    padding: 8px 15px;
    float: left;
    color: #FFF;
}

.Content {
    display: inline-block;
    float: left;
    width: 380px;
}

.Link {
    font-size: 15px;
    color: #424242;
    padding: 0px;
}

.Heading {
    font-size: 15px;
    margin: 0 0 0 0;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 10px;
}

.DueDate {
    color: #a9aaa9;
    font-size: 14px;
    padding-left: 10px;
}


.DeleteIcon {
    font-style: normal;
    color: #706B6B;
    font-size: 18px;
    margin: 0;
}
.Preloader {
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    opacity: 0.8;
    z-index: 2;
    position: fixed;
    text-align: center;
}

.LoaderDiv {
    margin-left: -240px;
    margin-top: -45px;
    display: block;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 480px;
}

.PreloaderImage {
    vertical-align: middle;
}

.LoadingText {
    color: #ffffff;
}


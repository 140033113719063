.storyTitle{
    width:100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    color: #024890;
    font-size: 25px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    min-height: 41px;
    line-height: 34px;
    font-family: 'latoregular', sans-serif;
    font-style: normal !important;
}

.AutosaveLabel {
    margin-left: 1rem;
    margin-top:5px;
    font-size: 10px;
    padding: 1px 4px;
    border-radius: 5px;
    word-break: initial;
    display: inline-block;
    background-color: #f3983e;
    color: #fff;
}

.luisUntrainedInfoLabel{
    margin-left: 1rem;
    margin-top:5px;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 5px;
    word-break: initial;
    display: inline-block;
    color: #fff;
}

.AutosaveLabelFa {
    color: #1bb71b;
}
.ActionBtn {
    padding: 0px 5px;
    font-size: 20px;
}

.ResponseText, .ResponseTag {
    padding: 5px;
}

.RuleResponsesTags {
    text-align: right;
    align-items: right;
}
.RecordPauseSection {
    padding: 5px 0 0 5px;
    bottom: 0px;
    left: 0px;
    height: 30px;
    width: 100%;
    background: #333333;
    position: absolute;
    z-index: 7777;
}

.PlayIcon {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    width: 34px;
    height: 34px;
    color: #fff;
    padding: 7px;
    border-radius: 50%;
    background: #0c488f;
}

.PlayStateMsg {
    color: white;
    margin-right: 19px;
    display: block;
}
.entityChip {
    
    cursor: pointer;
    /* width: 100%; */
    
   
}
.entityValueStyle{
    white-space: nowrap;
    max-width: 20px;
    width: 70% ;
    overflow: hidden;
    text-overflow: ellipsis;
   

}
.ShouldHave{
    color: red;
}
.ShouldNotHave{
    color: green;
}
.IconStyle{
    color:white;
    cursor:pointer;
    font-size:1.2rem;
}
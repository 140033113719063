.Title {
    font-size: 25px;
    /* font-weight: bold; */
    display: inline-block;
    vertical-align: top;
    color: #FFF;
    width: 100%;
    min-height: 41px;
    line-height: 34px;
    text-align: center;
    font-family: 'latoregular', sans-serif;
    font-style: normal !important;
}